<template>
  <footer class="bg-gray-900 text-white">
    <div class="text-center p-4">
      2022 Cryptimeleon |
      <router-link to="/privacy-policy" class="text-white hover:underline">Privacy Policy</router-link> |
      <router-link to="/impressum" class="text-white hover:underline">Impressum</router-link>
    </div>
  </footer>
</template>

<script setup>
</script>

<style scoped>

</style>